<template>
  <div v-if="product?.id" class="products-block">
    <a
      :href="`?product=${product.slug}`"
      class="image"
      @click.prevent="openProduct(product.slug)"
    >
      <CommonImage :data="product.mainImage" :height="170" :width="170" />
    </a>
    <div class="name">
      <a
        :href="`?product=${product.slug}`"
        @click.prevent="openProduct(product.slug)"
      >
        {{ product.title || "Неизвестный продукт" }}
      </a>
    </div>
    <div class="available">
      <img
        width="16"
        height="16"
        :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
        alt=""
      />
      {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 гр" }}
    </div>
    <template>
      <!--
        <div class="seller">
          <img width="16" height="16" src="/images/store.svg" alt="">
          {{ product?.partner.title || product?.partner.slug }}
        </div>
      -->
    </template>
    <div v-if="product.quantityStock" class="counting">
      <div class="add-block" :class="{ 'active-add': productFromCart?.count }">
        <div
          class="add-minus"
          :class="{ disabled: loading }"
          @click="removeFromCart(product.id)"
        >
          <img width="16" height="16" src="/images/minus.svg" alt="" />
        </div>
        <div class="price-block">
          <div v-if="product.priceDiscount" class="old-price">
            {{ priceFormat(product.price) }}
          </div>
          <div class="price" :class="{ discount: product.priceDiscount }">
            {{ priceFormat(product.priceDiscount ?? product.price) }}
          </div>
        </div>
        <div
          class="add-plus"
          :class="{ hidden: limitExceeded, disabled: loading }"
          @click="addToCart(product)"
        >
          <img width="16" height="16" src="/images/plus.svg" alt="" />
        </div>
      </div>
      <div v-if="productFromCart?.count" class="amount">
        <span>{{ `х ${productFromCart?.count || 0}` }}</span>
      </div>
    </div>
    <div v-else class="counting">
      <div class="not-available">Нет в наличии</div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCartStore } from "~/store/cart";
import type { Product } from "~/types/types";

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Product>,
      requried: true,
    },
  },
  emits: ["openProduct"],
  setup(props, { emit }) {
    const openProduct = (slug: string) => {
      emit("openProduct", slug);
    };
    // cart
    const store = useCartStore();
    const { addToCart, removeFromCart } = useCartStore();

    const loading = computed(() => {
      return store.loading;
    });
    const productFromCart = computed(() => {
      const { cart } = store;
      return cart.products?.find((p) => p?.id === props.product?.id) || null;
    });
    const limitExceeded = computed(() => {
      const { limits, cart } = store;
      if (productFromCart.value) {
        const { count, quantityStock } = productFromCart.value;
        return count >= limits.maxProductCount || count >= quantityStock;
      } else {
        const length = cart.products.length;
        return length >= limits.maxProductsLength;
      }
    });
    const { priceFormat } = usePriceFormat();

    return {
      productFromCart,
      limitExceeded,
      openProduct,

      loading,
      addToCart,
      removeFromCart,
      priceFormat,
    };
  },
});
</script>

<style scoped>
.image-empty {
  height: 170px;
  background: #f2f7f9;
}

.counting {
  margin-top: 1rem;
}
</style>