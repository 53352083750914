<template>
  <div class="mobile-catalog">
    <a href="#" class="btn" @click.prevent="handleOpen">
      <img width="20" height="20" src="/images/layout-list.svg" alt="" />
      Категории товаров
    </a>
  </div>

  <CommonSearchBar v-model="search" @clear="search = ''" />
  <ProductsPartnerList v-if="partners?.length" />

  <div class="main-inner" :class="{ loading }">
    <Loader v-if="(!isModalOpen && pending) || (!isModalOpen && displayLoader)" />
    <template v-if="products?.length">
      <div class="catalog-block">
        <div class="catalog-options">
          <client-only>
            <a v-if="showBackButton" href="?back" class="link-back" @click.prevent="handleBack">
              <img width="20" height="20" src="/images/arrow-left.svg" alt="" />
            </a>
            <a v-else-if="currentPartner?.id" href="?back" class="link-back" @click.prevent="clearPartner()">
              <img width="20" height="20" src="/images/arrow-left.svg" alt="" />
            </a>
            <h2>
              {{ currentCategory?.title || title }}
            </h2>
          </client-only>
          <div class="catalog-filter" :class="{ active: showCatalog }" @mouseover="showCatalog = true"
            @mouseleave="showCatalog = false">
            <div class="catalog-filter-toggle" :class="{ disabled: displayLoader || loading }">
              <img width="20" height="20" src="/images/filter.svg" alt="" />
              <span>Фильтр</span>
            </div>
            <div class="catalog-filter-podmenu">
              <a href="?sort=price" :class="{ active: params.sort === 'price' }" @click.prevent="setPriceSort('-')">
                Сначала дешевле
              </a>
              <a href="?sort=-price" :class="{ active: params.sort === '-price' }" @click.prevent="setPriceSort('+')">
                Сначала дороже
              </a>
            </div>
          </div>
        </div>
        <div :key="products?.length" class="products-assortment">
          <ProductsItem v-for="product in products" :key="product.id" :product="product" @openProduct="openProduct" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not-found-block">
        <a href="#" class="not-found-back" @click.prevent="
          params.search ? (params.search = null) : $router.push(toParams('/'))
          ">
          <img width="20" height="20" src="/images/arrow-left.svg" alt="" />
          <span>Вернуться назад</span>
        </a>
        <div class="not-found-description">
          <h4>Ничего не найдено.</h4>
          <div class="inner">
            <img width="30" height="30" src="/images/file-question.svg" alt="" />
            <p>
              К сожалению, в нашем каталоге нет товаров, подходящих под
              введенный поисковый запрос.
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>

  <Footer />
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useStore } from "~/store/index";
import { useProfileStore } from "~/store/profile";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";
import { useCartStore } from "~/store/cart";

import type { Category, Product } from "~/types/types";

interface ProductState {
  title: string;
  search: string;
  showCatalog: boolean;
}

export default defineComponent({
  async setup() {
    // route/r
    const router = useRouter();
    const route = useRoute();
    const { routeParameters } = useQueryNavigation();
    const { category } = route.params;
    const { search, product } = route.query;
    const { metaData, replaceUrlWithoutNavigation } = useSeoTags();

    // store
    const store = {
      admin: useAdminStore(),
      cart: useCartStore(),
      main: useStore(),
      modal: useModalStore(),
      partner: usePartnerStore(),
      profile: useProfileStore(),
    };

    const { default: defaultParams, params } = storeToRefs(store.main);
    const { modal } = storeToRefs(store.modal);

    // cart & partners logic
    const { current: currentPartner, partners, partnerId } = storeToRefs(store.partner);
    const { setPartner, clearPartner } = store.partner;

    const { cart } = storeToRefs(store.cart);

    // loader
    const displayLoader = ref<boolean>(true);
    const isMobile = ref(false);
    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 767.98;
    };
    onMounted(() => {
      displayLoader.value = false;

      if (process.client && typeof window != undefined) {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", updateIsMobile);
        updateIsMobile();
      }
    });
    onUnmounted(() => {
      if (process.client && typeof window != undefined) {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateIsMobile);
      }
    });

    // state
    const state = reactive<ProductState>({
      title: "Лучшие предложения",
      search: Array.isArray(search) ? search.join("+") : search || "",
      showCatalog: false,
    });

    // params
    store.main.replaceParams({
      categorySlug: Array.isArray(category)
        ? category.join("+")
        : category || null,
      search: Array.isArray(search) ? search.join("+") : search || null,
      sort: params.value.sort,
    });

    const { cityId } = storeToRefs(store.main);
    const {
      data: products,
      pending,
      refresh,
    } = await useAsyncData<Product[]>(
      "products",
      async () => await store.main.fetchProducts(),
      { watch: [cityId, partnerId] }
    );

    const currentCategory = computed(() => {
      let cat: Category | null =
        store.main.categories.find((cat) => cat.slug === category) || null;
      if (!cat) {
        store.main.categories.forEach((parentCat) => {
          if (!cat && parentCat.children) {
            const childCat = parentCat.children.find(
              (child) => child.slug === category
            );
            if (childCat) {
              cat = childCat;
            }
          }
        });
      }

      return cat;
    });

    // computed
    const showBackButton = computed(() => {
      let cat: Category | null =
        store.main.categories.find((cat) => cat.slug === category) || null;

      if (cat) {
        return route.path !== "/" && route.path === `/${cat.slug}`;
      } else {
        return route.path !== "/";
      }
    });

    // methods
    const handleOpen = () => {
      useModalStore().openMobileModal("catalog");
    };
    const handleBack = () => {
      let cat: Category | null =
        store.main.categories.find((cat) => cat.slug === category) || null;

      if (cat) {
        router.push(routeParameters("/"));
      } else {
        store.main.categories.forEach((parentCat) => {
          if (!cat && parentCat.children) {
            const childCat = parentCat.children.find(
              (child) => child.slug === category
            );
            if (childCat) {
              router.push(routeParameters(`/${parentCat.slug}`));
            }
          }
        });
      }
    };
    const setPriceSort = async (type: "-" | "+") => {
      let sort = type === "+" ? "-price" : type === "-" ? "price" : null;
      if (params.value.sort === sort) sort = null;

      store.main.updateParams({ sort });
      try {
        await refresh();
      } catch (e) {
        console.error("setPriceSort error", e);
      } finally {
        state.showCatalog = false;
      }
    };

    const handleSearch = async (search: string | null) => {
      clearPartner();

      const { category: cat } = useRoute().params;
      const categorySlug = Array.isArray(cat) ? cat.join("+") : cat || null;

      store.main.replaceParams({
        search,
        ...(search ? { categorySlug: null } : { categorySlug }),
        sort: params.value.sort,
      });

      try {
        await refresh();

        const query = { ...route.query };
        if (search) query.search = search;
        else delete query.search;
        router.replace({ query });
      } catch (e) {
        console.error("handleSearch error", e);
      }
    };

    const openProduct = async (slug: string) => {
      try {
        if (slug) {
          await store.main.fetchProductByArg(slug);
          useModalStore().openModal("product");

          /*
          const query = { ...route.query };
          query.product = slug;
          router.replace({ query });
          */

          const { product } = storeToRefs(store.main);
          if (product.value?.id) {
            useHead(metaData(product.value) as object);

            const { category } = product.value;
            const url = `${category?.slug ? `${category.slug}/` : ""}${slug}`;
            replaceUrlWithoutNavigation(url);
          }
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };
    if (product) {
      const slug = Array.isArray(product) ? product.join("+") : product || null;
      if (slug && slug != "undefined") {
        openProduct(slug);
      }
    }
    const loadMore = async () => {
      if (store.main.pagination.hasPages) {
        const { category: cat } = useRoute().params;
        const categorySlug = Array.isArray(cat) ? cat.join("+") : cat || null;

        if (params.value?.page) {
          const page = params.value.page + 1;

          store.main.updateParams({
            page,
            pageSize: defaultParams.value.pageSize,
            categorySlug,
          });
        } else {
          store.main.updateParams({
            page: 1,
            pageSize: defaultParams.value.pageSize,
            categorySlug,
          });
        }

        try {
          await refresh();
        } catch (e) {
          console.error("loadMore error", e);
        }
      }
    };
    const handleScroll = () => {
      if (isMobile.value) {
        const { scrollY, innerHeight } = window;
        const { scrollHeight } = document.body;
        const atHalfway = scrollY + innerHeight >= scrollHeight / 1.1;

        if (atHalfway && !loading.value) {
          loadMore();
        }
      } else {
        const { scrollHeight, scrollTop, clientHeight } =
          document.documentElement;
        const atBottom = scrollTop + clientHeight >= scrollHeight / 1.25;

        if (atBottom && !loading.value) {
          loadMore();
        }
        /*
        OLD LOGIC

        const { scrollHeight, scrollTop, clientHeight } =
          document.documentElement;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

        if (atBottom && !loading.value) {
          loadMore();
        }
        */
      }
    };

    // watchers
    watch(
      () => params.value.search,
      async (text) => {
        if (!text) state.search = "";
      }
    );
    watch(
      () => state.search,
      async (text, prev) => {
        if (text !== null && text !== prev) {
          await handleSearch(text || null);
        }
      }
    );
    watch(
      () => currentCategory.value?.slug,
      (slug) => {
        if (slug) {
          useModalStore().closeModal("error");
        }
        /*
        else if (category) {
          useModalStore().openModal("error");
        }
        */
      },
      { immediate: true }
    );
    // feat (обновляем данные при новых событиях)
    const { user } = storeToRefs(store.profile);
    watch(
      () => user.value?.actionsCount,
      async (count: number, oldCount: number) => {
        if (count !== oldCount) {
          await refresh();
        }
      }
    );
    // feat 2 (если открыта модалка, не показывать loader в каталоге)
    const isModalOpen = computed(() => {
      return Object.values(modal.value).includes(true);
    });
    const { loading } = storeToRefs(store.main);

    // feat 3 (при обновлении в админке, обновлять товары в каталоге)
    const { updateStatus } = storeToRefs(store.admin);
    watch(
      () => updateStatus.value,
      async (status: boolean) => {
        if (status) {
          await refresh();
          updateStatus.value = false;
        }
      }
    );

    // feat 4 (при обновлении города, возвращаемся на главную)
    watch(
      () => cityId.value,
      (id: string | null, prevId: string | null) => {
        if ((id && prevId) && id !== prevId) {
          router.push("/");
        }
      }
    );

    return {
      // state
      displayLoader,
      loading,
      pending,

      ...toRefs(state),
      currentCategory,
      showBackButton,

      params,
      isModalOpen,

      currentPartner,
      products,

      partners,
      setPartner,
      clearPartner,

      handleOpen,
      handleBack,
      setPriceSort,
      openProduct,
      toParams: routeParameters,
    };
  },
});
</script>

<style lang="less" scoped>
li .active {
  pointer-events: none;
}

.loading {
  cursor: wait;
  pointer-events: none;
  user-select: none;
}

.products-empty {
  min-height: 298.5px;
}

.catalog-filter-toggle.disabled {
  pointer-events: none;
}

.catalog-filter {
  &.active {
    .catalog-filter-toggle {
      color: #000;
      background: #fff;

      img {
        opacity: 1;
      }
    }

    .catalog-filter-podmenu {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>