import type { Category, ImageFormats, ProductInfo } from "~/types/types";

export function useSeoTags() {
    const seo = {
        title: "Product Express",
        url: process.server ? "https://product-express.ru/" : window.location.href,
        img: "https://product-express.ru/logo-small.png",
        description:
            "Продукт-Экспресс — онлайн-магазин с доставкой еды и продуктов питания. Приятные цены, отличное качество!",
        keywords:
            "Доставка продуктов в Анапе, Заказ продуктов с доставкой, Быстрая доставка продуктов, Онлайн заказ продуктов в Анапе, Продукты на дом в Анапе, Качественные продукты с доставкой, Удобная доставка продуктов по городу, Экспресс-доставка продуктов, Онлайн магазин продуктов в Анапе, Заказ и доставка продуктов онлайн, Высокое качество продуктов с доставкой, Заказать продукты с доставкой на дом в Анапе, Широкий ассортимент продуктов с доставкой, Продукты премиум класса с бесплатной доставкой, Доставка органических продуктов в Анапе, Доставка продуктов для офисов и домов, Заказ продуктов с сайта",
    };
    const defaultMeta = {
        title: "Product Express - Продукты с доставкой по Анапе",
        meta: [
            { name: "description", content: seo.description },
            { name: "keywords", content: seo.keywords },
            // Open Graph / Facebook
            { property: "og:title", content: seo.title },
            { property: "og:description", content: seo.description },
            { property: "og:type", content: "website" },
            { property: "og:url", content: seo.url },
            {
                property: "og:image",
                content: seo.img,
            },
            // twitter Card
            { name: "twitter:card", content: "summary_large_image" },
            // { name: "twitter:site", content: "@fishome777" },
            { name: "twitter:title", content: seo.title },
            { name: "twitter:description", content: seo.description },
            {
                name: "twitter:image",
                content: seo.img,
            },
            // custom tags
            { name: "robots", content: "index, follow" },
        ],
        link: [{ rel: "canonical", href: seo.url }],
    }
    function getFirstImage(images: ImageFormats) {
        if (images) {
            const keys = Object.keys(images);
            if (keys.length === 0) {
                return null;
            }
            const firstKey = keys[0];
            return images[firstKey]?.jpeg || seo.img;
        } else {
            return null;
        }
    }

    const metaData = (product?: ProductInfo) => {
        if (product) {
            const {
                brand = "Бренд",
                title = seo.title,
                price = "0₽",
                unit = "0 шт",
                description = "",
                mainImage,
            } = product;

            return {
                title: `${title} - ${price}₽/${unit}`,
                meta: [
                    { name: "description", content: description },
                    { name: "keywords", content: `Product Express, ${title}, ${brand}` },
                    // Open Graph / Facebook
                    { property: "og:title", content: `${title} - ${price}₽/${unit}` },
                    { property: "og:description", content: description },
                    { property: "og:type", content: "website" },
                    { property: "og:url", content: seo.url },
                    {
                        property: "og:image",
                        content: getFirstImage(mainImage),
                    },
                    // twitter Card
                    { name: "twitter:card", content: "summary_large_image" },
                    // { name: "twitter:site", content: "@fishome777" },
                    { name: "twitter:title", content: `${title} - ${price}₽/${unit}` },
                    { name: "twitter:description", content: description },
                    {
                        name: "twitter:image",
                        content: getFirstImage(mainImage),
                    },
                    // custom tags
                    { name: "robots", content: "index, follow" },
                ],
                link: [{ rel: "canonical", href: seo.url }],
            }
        }
        else {
            return defaultMeta
        }
    };

    const categoryMetaData = (category?: Category) => {
        if (category) {
            const {
                title = seo.title,
                description = seo.description
            } = category;

            return {
                title: `${title}`,
                meta: [
                    { name: "description", content: description || seo.description },
                    { name: "keywords", content: seo.keywords },
                    // Open Graph / Facebook
                    { property: "og:title", content: `${title}` },
                    { property: "og:description", content: description || seo.description },
                    { property: "og:type", content: "website" },
                    { property: "og:url", content: seo.url },
                    {
                        property: "og:image",
                        content: seo.img,
                    },
                    // twitter Card
                    { name: "twitter:card", content: "summary_large_image" },
                    // { name: "twitter:site", content: "@fishome777" },
                    { name: "twitter:title", content: `${title}` },
                    { name: "twitter:description", content: description || seo.description },
                    {
                        name: "twitter:image",
                        content: seo.img,
                    },
                    // custom tags
                    { name: "robots", content: "index, follow" },
                ],
                link: [{ rel: "canonical", href: seo.url }],
            }
        }
        else {
            return defaultMeta
        }
    };

    const replaceUrlWithoutNavigation = (path?: string) => {
        if (typeof window != undefined) {
            const baseUrl = window.location.origin;

            if (path) {
                const newUrl = `${baseUrl}/${path}`;
                window.history.replaceState({}, '', newUrl);
            } else {
                window.history.replaceState({}, '', '/');
            }
        }
    };

    return {
        metaData,
        categoryMetaData,
        replaceUrlWithoutNavigation
    }
}